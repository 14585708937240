import { FunctionComponent, ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PageNotFound from '@/views/pages/page-not-found';
import { ClientTypes, Flow } from '@/api/users/types';
import { getUser } from '@/lib/utils';

interface FlowGuardProps {
	flow: Flow;
	element: ReactNode;
}

const FlowGuard: FunctionComponent<FlowGuardProps> = ({ element, flow }): ReactElement => {
	const navigate = useNavigate();
	const { clientFlows, clientType } = getUser();
	const accessibleFlows = useMemo(() => clientFlows?.map((fl) => fl.flowName) || [], [clientFlows]);
	const isClientTypeNotSet = useMemo(() => clientType === ClientTypes.NOT_SET, [clientType]);

	useEffect(() => {
		if (isClientTypeNotSet) {
			navigate('../profile-type', { replace: true });
		}
	}, [isClientTypeNotSet, navigate]);

	if (!accessibleFlows.includes(flow)) {
		return <PageNotFound />;
	}

	return <>{element}</>;
};

export default FlowGuard;
