/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@tanstack/react-query';
import { ClientType } from '@/api/users/types';
import { setProfileType } from '@/api/users';

interface UseClientTypeProps {
	clientType: ClientType;
	onSuccess: (data: any) => void;
}

export const useProfileType = ({ clientType, onSuccess }: UseClientTypeProps) =>
	useMutation({
		mutationFn: () => setProfileType(clientType),
		onSuccess,
	});
