import { FunctionComponent, ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IntroLayout from '@/views/layouts/intro-layout';
import TaxNoTax from '@/components/ui/inputs/control-inputs/tax-no-tax-input';
import { ButtonNext } from '@/components/ui/button';
import { useTaxType } from '@/features/tax-no-tax';
import { AuthUser, TaxType, TaxTypes } from '@/api/users/types';
import { getUser, USER_INFO_KEY } from '@/lib/utils';

const RiskAssessmentIntro: FunctionComponent = (): ReactElement => {
	const navigate = useNavigate();
	const userInfo = getUser();
	const { taxType: selectedTaxType } = userInfo;
	const [taxType, setTaxType] = useState<TaxType>(selectedTaxType);

	const { mutate: onSubmit, isPending: isSubmitting } = useTaxType({
		taxType,
		onSuccess: ({ taxType }: AuthUser) => {
			localStorage.setItem(USER_INFO_KEY, JSON.stringify({ ...userInfo, taxType }));
			navigate('../risk-assessment/questionnaire');
		},
	});

	const chooseTaxType = (e: string) => {
		setTaxType(e as TaxType);
	};

	const goToRiskAssessment = () => {
		onSubmit();
	};

	return (
		<IntroLayout heading="Risk Assessment Questionnaire">
			<div className="flex flex-col gap-6">
				<p>
					These sections will assess your readiness for risk, decision-making patterns, and
					emotional impact on your investment decisions. Your responses will help us tailor advice
					to your financial profile.
				</p>
				<div className="flex flex-col gap-4">
					<div className="flex flex-row items-center gap-2">
						<div className="flex h-[28px] w-[28px] items-center justify-center rounded-full bg-blue-primary text-button-s">
							1
						</div>
						<span className="text-body-18-md">General Investment Questions</span>
					</div>
					<div className="flex flex-row items-center gap-2">
						<div className="flex h-[28px] w-[28px] items-center justify-center rounded-full bg-blue-primary text-button-s">
							2
						</div>
						<span className="text-body-18-md">Investment Behavior Questions</span>
					</div>
					<div className="flex flex-row items-center gap-2">
						<div className="flex h-[28px] w-[28px] items-center justify-center rounded-full bg-blue-primary text-button-s">
							3
						</div>
						<span className="text-body-18-md">Emotional Risk Tolerance</span>
					</div>
				</div>
				<div className="flex flex-col gap-2">
					<span className="text-body-16-md">Select your purpose of this exercise</span>
					<TaxNoTax
						withIcon
						value={taxType}
						taxValue={TaxTypes.TAX}
						noTaxValue={TaxTypes.NO_TAX}
						onChange={chooseTaxType}
					/>
				</div>
				<ButtonNext
					onClick={goToRiskAssessment}
					type="submit"
					size="lg"
					className="mt-2 justify-center"
					disabled={taxType === TaxTypes.NOT_SET || isSubmitting}
				>
					Start Risk Assessment Questionnaire
				</ButtonNext>
			</div>
		</IntroLayout>
	);
};

export default RiskAssessmentIntro;
