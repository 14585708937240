import { useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

interface UseSpeechToText {
	transcript: string;
	isListening: boolean;
	isBrowserSupported: boolean;
	startListening: () => Promise<void>;
	stopListening: () => Promise<void>;
	resetTranscript: () => void;
}

const speechRecognitionOptions = {
	continuous: true,
	interimResults: true,
	language: 'en-US',
};

const useSpeechToText = (
	instance: SpeechRecognition | null,
	transcribing: boolean
): UseSpeechToText => {
	useEffect(() => {
		const abortListening = async () => {
			if (!transcribing) await instance?.abortListening();
		};
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		abortListening();
	}, [instance, transcribing]);

	const {
		listening: isListening,
		browserSupportsSpeechRecognition,
		finalTranscript,
		resetTranscript,
	} = useSpeechRecognition({ transcribing });

	const startListening = async () => {
		if (instance) {
			(await instance?.startListening(speechRecognitionOptions)) as unknown as SpeechRecognition;
		}
	};

	const stopListening = async () => {
		if (instance) {
			await instance?.stopListening();
		}
	};

	return {
		transcript: finalTranscript,
		isListening,
		isBrowserSupported: browserSupportsSpeechRecognition,
		startListening,
		stopListening,
		resetTranscript,
	};
};

export default useSpeechToText;
