/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from '@tanstack/react-query';
import { TaxType } from '@/api/users/types';
import { setTaxType } from '@/api/users';

interface UseTaxTypeProps {
	taxType: TaxType;
	onSuccess: (data: any) => void;
}

export const useTaxType = ({ taxType, onSuccess }: UseTaxTypeProps) =>
	useMutation({
		mutationFn: () => setTaxType(taxType),
		onSuccess,
	});
