import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IntroLayout from '@/views/layouts/intro-layout';
import { ButtonNext } from '@/components/ui/button';
import { AuthUser, ClientType, ClientTypes } from '@/api/users/types';
import { useProfileType } from '@/features/profile-type/useProfileType';
import { getStartingFlowEntryPoint } from '@/router/utils/flows';
import { getClientId, getUser, USER_INFO_KEY } from '@/lib/utils';

const ProfileType: FunctionComponent = (): ReactElement => {
	const navigate = useNavigate();
	const clientId = getClientId();
	const userInfo = getUser();
	const [type, setType] = useState<ClientType>(ClientTypes.NOT_SET);

	const startingFlowEntryPoint = getStartingFlowEntryPoint();

	useEffect(() => {
		if (!startingFlowEntryPoint) navigate(`/${clientId}`);
	}, [startingFlowEntryPoint, navigate, clientId]);

	const { mutate: onSubmit, isPending: isSubmitting } = useProfileType({
		clientType: type,
		onSuccess: ({ clientType }: AuthUser) => {
			localStorage.setItem(USER_INFO_KEY, JSON.stringify({ ...userInfo, clientType }));
			if (startingFlowEntryPoint) navigate(`../${startingFlowEntryPoint}`);
		},
	});

	const setProfileType = (type: ClientType) => {
		setType(type);
		onSubmit();
	};

	return (
		<IntroLayout label="" heading="Your Profile Type">
			<div className="flex flex-col gap-6">
				<p>
					Select whether you’re completing this exercise for an individual or a household to ensure
					a personalized experience.
				</p>
				<div className="flex">
					<ButtonNext
						onClick={() => setProfileType(ClientTypes.INDIVIDUAL)}
						type="submit"
						size="lg"
						className="mr-6 justify-center"
						disabled={isSubmitting}
					>
						Individual
					</ButtonNext>
					<ButtonNext
						onClick={() => setProfileType(ClientTypes.HOUSEHOLD)}
						type="submit"
						size="lg"
						className="justify-center"
						disabled={isSubmitting}
					>
						Household
					</ButtonNext>
				</div>
			</div>
		</IntroLayout>
	);
};

export default ProfileType;
