import { RouteObject } from 'react-router-dom';
import ProfileType from '@/views/pages/profile-type';

export const independentRoutes: RouteObject[] = [
	{
		path: 'profile-type',
		index: true,
		element: <ProfileType />,
	},
];
