import { RouteObject } from 'react-router-dom';
import { defaultRoutes } from './defaultRoutes';
import { fastBrainRoutes } from './fast-brain';
import { slowBrainRoutes } from './slow-brain';
import { riskAssessmentRoutes } from './riskAssessmentRoutes';
import { portfolioRecommendationsRoutes } from './portfolioRecommendationsRoutes';
import { independentRoutes } from './independentRoutes';
import AuthLayer from '@/router/AuthLayer';
import PageNotFound from '@/views/pages/page-not-found';

const protectedRoutes: RouteObject[] = [
	{
		path: ':clientId',
		element: <AuthLayer />,
		children: [
			...defaultRoutes,
			...independentRoutes,
			...fastBrainRoutes,
			...slowBrainRoutes,
			...riskAssessmentRoutes,
			...portfolioRecommendationsRoutes,
			{
				path: '*',
				element: <PageNotFound />,
			},
		],
	},
];

export default protectedRoutes;
