import RadioInput from '../radio-input';
import './tax-no-tax.scss';

interface TaxNoTaxProps {
	id?: string;
	name?: string;
	value?: string;
	taxValue?: string;
	noTaxValue?: string;
	onChange?: (e: string) => void;
	withIcon?: boolean;
}

const TaxNoTax: React.FC<TaxNoTaxProps> = ({
	id = 'answer',
	name = 'answer-tax-no-tax',
	value,
	taxValue,
	noTaxValue,
	onChange,
	withIcon = false,
}) => (
	<div className="tax-no-tax">
		<RadioInput
			id={`${id}-tax`}
			name={name}
			label="Tax Purposes"
			value={taxValue}
			withIcon={withIcon}
			onChange={onChange}
			checked={value === taxValue}
		/>
		<RadioInput
			id={`${id}-no-tax`}
			name={name}
			label="No Tax Purposes"
			value={noTaxValue}
			withIcon={withIcon}
			onChange={onChange}
			checked={value === noTaxValue}
		/>
	</div>
);

export default TaxNoTax;
