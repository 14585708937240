/* eslint-disable @typescript-eslint/no-misused-promises */
import { forwardRef, ReactElement, useEffect, useRef, useState } from 'react';
import SpeechRecognition from 'react-speech-recognition';
import { TextField } from '@/components/ui/inputs';
import QuestionAbstract, { QuestionAbstractProps } from './QuestionAbstract';
import useSpeechToText from '../question-group/useSpeechToText';
import ButtonPrimary from '@/components/ui/button/button-primary/ButtonPrimary';
import { Microphone } from '@/assets/icons/Microphone';
import '@/scss/_question-open.scss';

export interface QuestionOpenProps extends Omit<QuestionAbstractProps, 'children'> {
	answer: string;
	onChange: (questionId: string, answer: string) => void;
	onPressEnter?: () => void;
	placeholder?: string;
	showInfo?: boolean;
	isFocused?: boolean;
	classes?: string[];
}

export const QuestionOpen = forwardRef<HTMLDivElement, QuestionOpenProps>(
	(
		{
			question,
			answer,
			onChange,
			onPressEnter,
			placeholder,
			showInfo,
			isFocused,
			classes = [],
			...questionAbstractProps
		},
		ref
	): ReactElement => {
		const textInputRef = useRef<HTMLTextAreaElement>(null);
		const recognitionInstanceRef = useRef<(SpeechRecognition | null)[]>([]);
		const [transcribing, setTranscribing] = useState<boolean>(false);
		const [text, setText] = useState('');
		const transcriptRef = useRef('');

		useEffect(() => {
			setText(answer);
		}, [answer]);

		// Control focused state
		useEffect(() => {
			if (isFocused) {
				setTranscribing(true);
				recognitionInstanceRef.current[question.orderNo] = SpeechRecognition;
				textInputRef.current?.focus({ preventScroll: true });
			} else {
				setTranscribing(false);
				textInputRef.current?.blur();
			}
		}, [isFocused, question.orderNo]);

		const {
			transcript,
			isListening,
			isBrowserSupported,
			startListening,
			stopListening,
			resetTranscript,
		} = useSpeechToText(recognitionInstanceRef.current[question.orderNo], transcribing);

		const onInputChange = (text: string) => {
			if (!text || text.length < answer.length) {
				resetTranscript();
			}
			onChange(question.id, text);
		};

		// Append new speech to the existing text
		useEffect(() => {
			if (transcript) {
				// If the text is cleared (e.g., when the user starts over), reset transcriptRef.current
				if (text === '') {
					transcriptRef.current = ''; // Reset the reference
				}

				// Get the new speech by slicing out the previous portion
				const newSpeech = transcript.slice(transcriptRef.current.length).trim();

				if (newSpeech) {
					const words = newSpeech.split(' '); // Split into individual words
					const fixedSpeech = words.join(' '); // Join words without partial breaks

					// Append the fixed speech to the current text
					setText((prev) => `${prev}${prev ? ' ' : ''}${fixedSpeech}`);
				}

				// Update the ref with the current transcript
				transcriptRef.current = transcript;
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [transcript]);

		return (
			<QuestionAbstract {...{ ...questionAbstractProps, question }} ref={ref}>
				<TextField
					ref={textInputRef}
					id={`${question.id}-question`}
					name={`${question.id}-question`}
					value={text}
					onChange={(answer) => onInputChange(answer)}
					onPressEnter={onPressEnter}
					placeholder={placeholder}
					showInfo={showInfo}
					classes={classes}
				>
					{isBrowserSupported &&
						(isListening ? (
							<ButtonPrimary
								size="md"
								className="stop-speech-btn"
								onClick={stopListening}
								type="button"
							>
								<div className="h-[14px] w-[14px] rounded-sm bg-white"></div>
							</ButtonPrimary>
						) : (
							<ButtonPrimary
								size="md"
								className="start-speech-btn"
								icon={<Microphone />}
								onClick={startListening}
								type="button"
							/>
						))}
				</TextField>
			</QuestionAbstract>
		);
	}
);

QuestionOpen.displayName = 'Question Open';

export default QuestionOpen;
