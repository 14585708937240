import { httpClient } from '@/api/http';
import { AuthUser, ClientType, ClientTypePayload, TaxType, TaxTypePayload, User } from './types';

export const getAuthUser = async (clientId: string): Promise<User> => {
	const { data } = await httpClient.get<User>(`/clients/erp/${clientId}`);

	return data;
};

export const login = async (password: string, erpId: string): Promise<AuthUser> => {
	const { data } = await httpClient.post<AuthUser>(`/authorize-client`, { password, erpId });

	return data;
};

export const setProfileType = async (clientType: ClientType): Promise<ClientTypePayload> => {
	const { data } = await httpClient.put<ClientTypePayload>('/client-type', { clientType });

	return data;
};

export const setTaxType = async (taxType: TaxType): Promise<TaxTypePayload> => {
	const { data } = await httpClient.put<TaxTypePayload>('/client-tax-type', { taxType });

	return data;
};
