/* eslint-disable no-param-reassign, react/no-unknown-property */
import { useRef } from 'react';
import '@babylonjs/loaders/glTF/2.0';
import type { SceneEventArgs } from 'react-babylonjs';
import { Scene } from 'react-babylonjs';
import { ActionManager, Color3, Vector3, ExecuteCodeAction } from '@babylonjs/core';
import type { Scene as BabylonScene, FreeCamera, PBRMaterial } from '@babylonjs/core';

import {
	addTextToTile,
	createCameraFOVAnimation,
	createHoverAnimation,
	createShadowGenerator,
	createStackingAnimation,
	forceEndFrameAnimation,
	getFirstAvailableSlot,
	loadAndProcessScene,
	playAnimation,
	resetQuaterinionRotation,
	setupSpotlight,
	showInspector,
	updateStackingAnimationPosition,
	updateStackingAnimationStartPositionAndRotation,
	processMaterials,
	setDominoMaterialName,
} from '@/views/scenes/utils/tiles.utils';

import {
	InternalTileData,
	TileData,
	LoadResult,
	TileSceneProps,
} from '@/views/scenes/types/tiles.types';
import { TileSelected } from '@/api/tile-groups/types';

import {
	fastBrainSlotsData,
	fastBrainTilesData,
	isSceneLoading,
	updateSlotData,
	updateTileHoverAnimation,
	updateTileIsSelected,
	updateTileStackingAnimation,
} from '@/views/scenes/signals/tiles.signals';

export const FastBrainTileScene = ({ tilesGroup, selectedTilesGroup }: TileSceneProps) => {
	const sceneRef = useRef<BabylonScene>();
	const canvasRef = useRef<HTMLCanvasElement>();
	const cameraRef = useRef<FreeCamera>();
	const isInteractionReady = useRef<boolean>(false);
	const sceneUrl = '/models/domino_scene.glb';
	const isInspectorOn = import.meta.env.VITE_ENVIRONMENT === 'local';

	// data coming from API
	const tiles = tilesGroup[0]?.tiles ? tilesGroup[0].tiles : [];
	const tilesSelected = selectedTilesGroup[0]?.tiles ? selectedTilesGroup[0].tiles : [];

	const isInitialLoad = useRef<boolean>(!(tilesSelected && tilesSelected.length > 0));

	// restore selected tiles to their selected state
	const restoreSelectedTileToSlot = (
		tile: InternalTileData,
		selectedTiles: TileSelected[],
		animationStartTransform: [Vector3, number],
		scene: BabylonScene
	) => {
		const slots = fastBrainSlotsData.value;
		const tiles = fastBrainTilesData.value;

		const targetTile = tiles.filter((t) => t.name === tile.name)[0];
		const targetMesh = targetTile.mesh;
		targetMesh.isPickable = false;
		const selectedTile = selectedTiles.filter(
			(selectedTile) => selectedTile.slot.targetTile === tile.name
		)[0];
		const selectedTileSlotId = selectedTile.slot.slotId;
		const targetSlot = slots.filter((slot) => slot.id === selectedTileSlotId)[0];

		const tileData: TileData = {
			tag: targetTile.tileTag,
			id: targetTile.name,
			text: targetTile.tileText,
		};

		updateSlotData(fastBrainSlotsData, targetSlot.id, true, tileData);

		let { stackingAnimation } = targetTile;
		if (!stackingAnimation) {
			stackingAnimation = createStackingAnimation(
				targetTile,
				new Vector3(...targetSlot.position),
				scene
			);
			updateTileStackingAnimation(fastBrainTilesData, targetTile.name, stackingAnimation);
		}
		resetQuaterinionRotation(targetTile);
		updateStackingAnimationStartPositionAndRotation(
			stackingAnimation,
			animationStartTransform[0],
			animationStartTransform[1]
		);
		forceEndFrameAnimation(stackingAnimation);
		stackingAnimation.onAnimationGroupEndObservable.addOnce(() => {
			targetTile.mesh.isPickable = true;
		});
	};

	const addTileToFirstAvailableSlot = (tile: InternalTileData, scene: BabylonScene) => {
		const slots = fastBrainSlotsData.value;
		const tiles = fastBrainTilesData.value;
		const targetSlot = getFirstAvailableSlot(slots);
		if (!targetSlot) return;

		const slotPosition = new Vector3(
			targetSlot.position[0],
			targetSlot.position[1],
			targetSlot.position[2]
		);

		const tileData: TileData = {
			tag: tile.tileTag,
			id: tile.name,
			text: tile.tileText,
		};

		updateSlotData(fastBrainSlotsData, targetSlot.id, true, tileData);
		const targetTile = tiles.filter((t) => t.name === tile.name)[0];
		targetTile.mesh.isPickable = false;
		targetTile.mesh.computeWorldMatrix(true);
		let { stackingAnimation } = targetTile;
		if (!stackingAnimation) {
			stackingAnimation = createStackingAnimation(targetTile, slotPosition, scene);
			updateTileStackingAnimation(fastBrainTilesData, targetTile.name, stackingAnimation);
		}
		stackingAnimation.stop();
		updateStackingAnimationPosition(stackingAnimation, slotPosition);
		stackingAnimation.start(false, 1, stackingAnimation.from, stackingAnimation.to);
		stackingAnimation.onAnimationGroupEndObservable.addOnce(() => {
			targetTile.mesh.isPickable = true;
		});
	};

	const removeTileFromSlot = (tile: InternalTileData) => {
		const slots = fastBrainSlotsData.value;
		const tiles = fastBrainTilesData.value;
		const targetTile = tiles.filter((t) => t.name === tile.name)[0];
		const targetSlot = slots.find((slot) => slot.tile?.id === targetTile.name);
		if (!targetSlot) return;
		updateSlotData(fastBrainSlotsData, targetSlot.id, false, null);
		const { stackingAnimation } = targetTile;
		if (!stackingAnimation) return;
		stackingAnimation.stop();
		stackingAnimation.start(false, 1, stackingAnimation.to, stackingAnimation.from);

		stackingAnimation.onAnimationGroupEndObservable.addOnce(() => {
			targetTile.mesh.isPickable = true;

			const { hoverAnimation } = targetTile;
			if (hoverAnimation) {
				const targetStart = hoverAnimation.animatables[0]?.masterFrame || hoverAnimation.to;
				hoverAnimation.stop();
				hoverAnimation.start(false, 5, targetStart, hoverAnimation.from);
			}
		});
	};

	const handleTilePick = (tile: InternalTileData) => {
		if (!isInteractionReady.current) return;
		const scene = sceneRef.current;
		if (!scene) return;
		const data = fastBrainTilesData.value;
		const selectedTile = data.filter((t) => t.name === tile.name);
		selectedTile[0].mesh.isPickable = false;
		if (!selectedTile[0].isSelected) {
			updateTileIsSelected(fastBrainTilesData, selectedTile[0].name, true);
			addTileToFirstAvailableSlot(selectedTile[0], scene);
		} else {
			updateTileIsSelected(fastBrainTilesData, selectedTile[0].name, false);
			removeTileFromSlot(selectedTile[0]);
		}
	};

	const handleTileOver = (tile: InternalTileData, scene: BabylonScene) => {
		if (!isInteractionReady.current) return;

		const targetTile = fastBrainTilesData.value.filter((t) => t.name === tile.name)[0];
		if (targetTile.isSelected) return;
		if (targetTile.stackingAnimation?.isPlaying) return;
		let { hoverAnimation } = targetTile;
		if (!hoverAnimation) {
			hoverAnimation = createHoverAnimation(targetTile, scene);
			updateTileHoverAnimation(fastBrainTilesData, targetTile.name, hoverAnimation);
		}
		const targetStart = hoverAnimation.animatables[0]?.masterFrame || hoverAnimation.from;
		hoverAnimation.stop();
		hoverAnimation.start(false, 3, targetStart, hoverAnimation.to);
	};

	const handleTileOut = (tile: InternalTileData) => {
		if (!isInteractionReady.current) return;
		const targetTile = fastBrainTilesData.value.filter((t) => t.name === tile.name)[0];
		if (targetTile.isSelected) return;
		if (targetTile.stackingAnimation?.isPlaying) return;

		const { hoverAnimation } = targetTile;
		if (!hoverAnimation) return;
		const targetStart = hoverAnimation.animatables[0]?.masterFrame || hoverAnimation.to;
		hoverAnimation.stop();
		hoverAnimation.start(false, 4, targetStart, hoverAnimation.from);
	};

	const setupScene = ({ scene }: SceneEventArgs) => {
		sceneRef.current = scene;
		canvasRef.current = scene.getEngine().getRenderingCanvas() as HTMLCanvasElement;

		loadAndProcessScene(sceneUrl, tiles, tilesSelected, scene)
			.then(({ tiles, slots }: LoadResult<InternalTileData>) => {
				fastBrainTilesData.value = tiles;
				fastBrainSlotsData.value = slots;

				scene.stopAllAnimations();

				const spotlight = setupSpotlight(scene);
				const shadowGenerator = createShadowGenerator(spotlight);

				const dominoMaterial = tiles[0].mesh.material as PBRMaterial;
				if (dominoMaterial) {
					setDominoMaterialName(dominoMaterial, 'FastBrain');
				}

				tiles.forEach((tile: InternalTileData) => {
					shadowGenerator.addShadowCaster(tile.mesh);
					const targetTile = fastBrainTilesData.value.filter((t) => t.name === tile.name)[0];
					// tile ACTIONS PICK, HOVER OVER, HOVER OUT
					targetTile.mesh.actionManager = new ActionManager(scene);
					targetTile.mesh.actionManager.registerAction(
						new ExecuteCodeAction(ActionManager.OnPickTrigger, () => handleTilePick(tile))
					);
					targetTile.mesh.actionManager.registerAction(
						new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () =>
							handleTileOver(tile, scene)
						)
					);
					targetTile.mesh.actionManager.registerAction(
						new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, () => handleTileOut(tile))
					);

					// add text to tile
					const targetMaterialName = targetTile.mesh.material?.name || null;
					addTextToTile(targetTile, targetMaterialName);
				});
				// start animations if initial load, skip animations if not
				if (isInitialLoad.current) {
					tiles.forEach((tile: InternalTileData) => {
						if (tile.dropAnimation) {
							playAnimation(tile.dropAnimation);
						}
					});
				} else {
					tiles.forEach((tile: InternalTileData) => {
						if (tile.dropAnimation) {
							forceEndFrameAnimation(tile.dropAnimation);
						}
						// when drop animation is done, if there are selected tiles, restore their state in the slots
						tile.dropAnimation?.onAnimationGroupEndObservable.addOnce(() => {
							const selected = fastBrainTilesData.value.filter((tile) => tile.isSelected);
							selected.forEach((tile) => {
								const animStartPosition = tile.mesh.position;
								const animStartRotationY = tile.mesh.rotation.y;
								restoreSelectedTileToSlot(
									tile,
									tilesSelected,
									[animStartPosition, animStartRotationY],
									scene
								);
							});
						});
					});
				}
			})
			.catch((error) => {
				console.error('An error occurred while loading and processing the scene:', error);
			});

		scene.onReadyObservable.add(() => {
			// camera animations
			const camera = scene.cameras.find((c) => c.getClassName() === 'FreeCamera');
			if (camera) {
				cameraRef.current = camera as FreeCamera;
				scene.activeCamera = camera;

				const cameraAnimationName = 'CameraAction';
				const cameraAnimation = scene.animationGroups.find((animation) =>
					animation.name.includes(cameraAnimationName)
				);

				if (cameraAnimation) {
					const cameraFOVAnimation = createCameraFOVAnimation(camera as FreeCamera, 300, scene);

					cameraAnimation.onAnimationGroupEndObservable.add(() => {
						isInteractionReady.current = true;
					});

					if (isInitialLoad.current) {
						playAnimation(cameraAnimation);
						playAnimation(cameraFOVAnimation);
					} else {
						forceEndFrameAnimation(cameraAnimation);
						forceEndFrameAnimation(cameraFOVAnimation);
					}
				} else {
					console.warn(
						`There is no camera animation with the name ${cameraAnimationName} in the scene`
					);
				}
			}

			processMaterials(scene.materials);
			isSceneLoading.value = false;

			if (isInspectorOn) showInspector(scene);
		});
	};

	return (
		<Scene
			onSceneMount={(scene: SceneEventArgs) => {
				setupScene(scene);
			}}
		>
			<arcRotateCamera
				name="debugCamera"
				alpha={Math.PI / 2}
				beta={0.3}
				radius={15}
				target={new Vector3(0.8, 0, -3)}
				minZ={0}
				wheelPrecision={20}
			/>
			<hemisphericLight
				name="ambientLight"
				direction={new Vector3(0, 1, 0)}
				specular={new Color3(0, 0, 0)}
				intensity={0.8}
			/>
		</Scene>
	);
};

export default FastBrainTileScene;
