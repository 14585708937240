export interface User {
	id: string;
	erpId: string;
	clientName: string;
	clientEmail: string;
	ownerId: string | null;
}

export enum Flow {
	FAST_BRAIN = 'fast_brain',
	SLOW_BRAIN = 'slow_brain',
	RISK_ASSESSMENT = 'risk_assessment',
	PORTFOLIO_RECOMMENDATIONS = 'recommendation',
}

export enum ClientTypes {
	NOT_SET = 'NOT_SET',
	HOUSEHOLD = 'HOUSEHOLD',
	INDIVIDUAL = 'INDIVIDUAL',
}

export type ClientType = ClientTypes.NOT_SET | ClientTypes.HOUSEHOLD | ClientTypes.INDIVIDUAL;

export enum TaxTypes {
	NOT_SET = 'NOT_SET',
	NO_TAX = 'NO_TAX',
	TAX = 'TAX',
}

export type TaxType = TaxTypes.NOT_SET | TaxTypes.NO_TAX | TaxTypes.TAX;

export interface ClientFlows {
	flowId: number;
	flowName: Flow;
	flowOrder: number;
	status: string;
}

export interface AuthUser {
	id: number;
	erpId: string;
	jwt: string;
	clientName: string;
	clientEmail: string;
	clientFlows: ClientFlows[];
	clientType: ClientType;
	taxType: TaxType;
}

export interface ClientTypePayload {
	clientType: ClientType;
}

export interface TaxTypePayload {
	clientType: TaxType;
}
