import { FunctionComponent, ReactNode } from 'react';
import { motion as m } from 'framer-motion';
import LinkButton from '@/components/ui/link-button';
import PageLayout from '../page-layout';
import './intro-layout.scss';

interface IntroLayoutProps {
	children: ReactNode;
	label?: ReactNode;
	heading: ReactNode;
	link?: {
		to: string;
		text: string;
	};
}

const IntroLayout: FunctionComponent<IntroLayoutProps> = ({ children, label, heading, link }) => {
	const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

	const itemVariants = {
		hidden: { opacity: 0, y: 10 },
		visible,
	};

	return (
		<PageLayout theme="dark">
			<m.div
				initial="hidden"
				animate="visible"
				exit={{ opacity: 0, transition: { duration: 1 } }}
				variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
				className="intro-layout-container"
			>
				<m.div variants={itemVariants} className="intro-layout-title-container">
					{label && <span className="intro-layout-title-container__label">{label}</span>}
					<h1 className="intro-layout-title-container__heading">{heading}</h1>
				</m.div>
				<m.div variants={itemVariants} className="text-body-22">
					{children}
				</m.div>
				<m.div variants={itemVariants}>
					{link && <LinkButton to={link.to}>{link.text}</LinkButton>}
				</m.div>
			</m.div>
		</PageLayout>
	);
};

export default IntroLayout;
